.banner {
    background-color: #3b414a;
    color: #f2f2f2;
    padding: 3rem;
    position: relative;
    display: flex;
    min-height: 40vh;
    justify-content: center;
    align-items: center;
}

.sections {
    position: absolute;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.title {
    font-size: 3rem;
}

.questions {
    font-size: 18px;
}