.Reviews {
    color: #929292;
    text-align: center;
    font-family:
        'Segoe UI',
        'Roboto',
        'Oxygen',
        'Ubuntu',
        'Cantarell',
        'Fira Sans',
        'Droid Sans',
        'Helvetica Neue',
        sans-serif;
    border-top: #7c8798 solid 3px;
    width: 90%;
    margin: auto;
    margin-top: 1rem;
}

.starIcon {
    color: #fcdb03;
}

.slick-slide>div {
    margin: 0 10px;
}

.slick-list {
    margin: 0 -10px;
}

.reviewItem {
    background-color: #fff;
    min-width: 17rem;
    min-height: 14rem;
    overflow: hidden;
    box-shadow: 0px 5px 10px 0px #929292;
    font-family:
        'Segoe UI',
        'Roboto',
        'Oxygen',
        'Ubuntu',
        'Cantarell',
        'Fira Sans',
        'Droid Sans',
        'Helvetica Neue',
        sans-serif;
}

.reviewItem:hover {
    /* background-color: ; */
}

.slick-slider {
    width: 85%;
    margin: auto;
}

.slick-prev:before,
.slick-next:before {
    color: #010000 !important;
}

.slick-prev:hover,
.slick-next:hover {
    background-color: #7c8798;
}

.reviewTitle {
    border-bottom: #7c8798 solid 3px;
}

.cardTop,
.cardBody {
    padding: 1rem;
}


.advisorLink {
    text-decoration: none;
}

.reviewDate {
    font-size: 14px;
}

.advisorLink,
.reviewDate {
    color: #a1a5ac;
    float: right;
}