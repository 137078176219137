.logo {
    max-width: 17rem;
    max-height: 7rem;
}

nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #ffff;
    padding: 20px 80px;
    /* border-bottom: 2px solid black; */
    max-height: 8rem;
}

.navbar {
    display: flex;
    align-items: center;
    justify-content: center;
}

.navbar li {
    list-style: none;
    padding: 0 20px;
    position: relative;
}

.navItem {
    text-decoration: none;
    color: #7c8798;
    font-weight: 700;
    font-family:
        'Segoe UI',
        'Roboto',
        'Oxygen',
        'Ubuntu',
        'Cantarell',
        'Fira Sans',
        'Droid Sans',
        'Helvetica Neue',
        sans-serif;
    font-size: 18px;
}

.navItem:hover {
    text-decoration: underline;
}

.navQuickInfo {
    display: block;
    max-width: 22%;
    background-color: #7c8798;
    float: right;
    font-size: 18px;
    min-height: 7rem;
    min-width: 9rem;
    margin: auto;
    margin-right: 0;
    padding: 1rem;
}

.mobile {
    display: none;
}

@media screen and (max-width: 769px) {
    .navbar {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        position: fixed;
        top: 122px;
        right: -350px;
        width: 300px;
        height: 100vh;
        background-color: #929292;
        padding: 40px 0 0 10px;
        transition: 0.3s ease-in-out;
        z-index: 2;
    }

    .navbar.active {
        right: 0px;
        z-index: 200;
    }

    .navbar li {
        margin-bottom: 25px;
    }

    .mobile {
        display: block;
        font-size: x-large;
    }

    .navItem {
        color: #ffff;
    }

    .navQuickInfo {
        display: none;
    }
}