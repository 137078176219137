.materials_subContainer{
    display: grid;
    grid-template-columns:repeat(4,1fr); 
    grid-template-rows: repeat(5,1fr); 
    background: lightblue;
    min-height: 60vh;
    max-width: 100%;
    flex-direction: column;


    padding: 0.5rem;
    width: 35rem;
    margin: 1rem 0rem 0rem 10rem;
    background-color: #929292;
    box-shadow: 0px 5px 10px 0px #929292;
    color: #ffff;
    font-family:
        'Segoe UI',
        'Roboto',
        'Oxygen',
        'Ubuntu',
        'Cantarell',
        'Fira Sans',
        'Droid Sans',
        'Helvetica Neue',
        sans-serif;
}
.materials_container{
    display: flex;
    padding: 5px;
    justify-content: space-around;
}
.materials_exampleImage{
    height: 20vh;

}
.materials_header{
    font-size: 2rem;
    text-align: center;
    height: 10rem;
    text-transform: uppercase;


}

.materials_subContainer-name{
    grid-row-start: 1;
    grid-column: 1/ span 3;
    font-size: 3rem;
}
.materials_subContainer-desc{
    grid-row-start: 1;
    grid-column: 4/ span 2;
}
.materials_subContainer-ideal{
    grid-row-start: 2;
    grid-column: 1/span 4
}

.materials_subContainer-outside{
    grid-row-start: 3;
    grid-column: 1/span 4;
}
.materials_subContainer-seal{
    grid-row-start: 4;
    grid-column: 1/span 4;
}
.materials_subContainer-value{
    grid-row-start: 5;
    grid-column: 1/span 3;
}
.materials_subContainer-valueDesc{
    grid-row-start: 5;
    grid-column: 3 /span 3;
}
.materials_subContainer-example{
    display: grid;

    position: relative;
    grid-template-columns:repeat(4,1fr); 
    background: none;
    color: grey;
    grid-auto-rows:30%;
    top:5rem;
    grid-gap: 1.5px;
    box-shadow: none;
}
.materials_subContainer-item{
    height: 14rem;
    width: 14rem;

    -webkit-clip-path: polygon(50% 0%,100% 25%,100% 75%, 50% 100%, 0% 75%, 0% 25%);
    clip-path: polygon(50% 0%,100% 25%,100% 75%, 50% 100%, 0% 75%, 0% 25%);
   
    background: tan;
}
.materials_subContainer-item:first-child{
    grid-row-start: 1;
    grid-column: 1/span 2 ;
}
.materials_subContainer-item:nth-child(2){
    grid-row-start: 1;
    grid-column: 3/span 2 ;
}
.materials_subContainer-item:nth-child(3){
    grid-row-start: 1;
    grid-column: 5/span 2 ;
}
.materials_subContainer-item:nth-child(4){
    grid-row-start: 2;
    grid-column: 2/span 2 ;
}
.materials_subContainer-item:nth-child(5){
    grid-row-start: 2;
    grid-column: 4/span 2 ;
}
.image {
    height: 50vh;
    position: sticky;

}

@media (max-width: 640px) {
    .image {
        max-height: 30vh;
    }
}