.footer {
    min-height: 15rem;
    min-width: 100%;
    background-color: #929292;
    padding: 3rem;
    position: relative;
    bottom: 0;
    margin-top: 2rem;
    display: flex;
    font-family: 'Segoe UI',
        'Roboto',
        'Oxygen',
        'Ubuntu',
        'Cantarell',
        'Fira Sans',
        'Droid Sans',
        'Helvetica Neue',
        sans-serif;
    ;
    font-size: 16px;
    justify-content: space-between;
}

.footerInfo {
    margin-bottom: 1rem;
    line-height: 2rem;
    padding-bottom: 1rem;
    color: #fff;
}

.footer-office,
.footer-cell,
.footerLinks {
    color: #fff;
    text-decoration: none;
}

.footerLinks {
    line-height: 2rem;
    list-style: none !important;
}

.footerLinks:hover,
.footer-phone:hover {
    text-decoration: underline;
}

.socialMedia {
    color: #fff;
    text-align: center;
}

.facebook {
    margin-bottom: 1rem;
}

.facebookIcon {
    color: #4267B2;
    font-size: 24px;
}

.footer-copyright {
    background-color: #929292;
    max-width: 100%;
    display: flex;
    color: #fff;
}

.loyaCopyright {
    height: 3rem;
    text-align: center;
    width: 80%;
    padding: 1rem;
    border-top: solid #000 2px;
    margin: 0 auto;
}

.topRated,
.eliteService {
    height: fit-content;
}

/* @media (max-width: 640px) {

    .footer-copyright {
        margin-left: 0;
    }
} */

@media screen and (max-width: 769px) {
    .footer {
        display: flex;
        flex-direction: column;
        margin-top: 3rem;
        padding: 0;
    }

    .footerInfo {
        text-align: center;
    }

    .links {
        /* margin-bottom: 1rem; */
        margin: auto;
    }

    .haLogo {
        margin: auto;
    }

    .socialMedia {
        margin-top: 1rem;
    }


}