/* .wrapper {
   
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    perspective: 10px;
} */

.aboutTitle {
    background-color: #ffff;
    text-align: center;
    font-family:
        'Segoe UI',
        'Roboto',
        'Oxygen',
        'Ubuntu',
        'Cantarell',
        'Fira Sans',
        'Droid Sans',
        'Helvetica Neue',
        sans-serif;
}

.aboutHeader {
    position: relative;
    height: 100%;
    transform-style: preserve-3d;
    z-index: -1;
}

.background {
    /* min-height: 500px;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover; */

    object-fit: cover;
    position: absolute;
    height: 100%;
    width: 100%;
    transform: translateZ(-10px) scale(2);
}

.aboutbottom {
    height: 1000px;

}

.loyaGranite {
    margin-top: 2rem;
}

.spanishToggle {
    font-family:
        'Segoe UI',
        'Roboto',
        'Oxygen',
        'Ubuntu',
        'Cantarell',
        'Fira Sans',
        'Droid Sans',
        'Helvetica Neue',
        sans-serif;
    font-weight: 600;
    color: #ffff;
    background: #929292;
    height: 2rem;
    float: right;
    cursor: pointer;
    padding: 3px;
    margin-right: 5px;
}

.spanishToggle:hover {
    color: #fcdb03;
    text-decoration: underline;
    transition: 1s;
}

.aboutLoyaGraniteSection {
    padding: 0.5rem;
    width: 35rem;
    margin: 1rem 0rem 0rem 10rem;
    float: left;
    background-color: #929292;
    box-shadow: 0px 5px 10px 0px #929292;
    color: #ffff;
    font-family:
        'Segoe UI',
        'Roboto',
        'Oxygen',
        'Ubuntu',
        'Cantarell',
        'Fira Sans',
        'Droid Sans',
        'Helvetica Neue',
        sans-serif;
}

.loyaGraniteName {
    font-weight: 700;
}

.loyaGraniteName,
.aboutLoya {
    display: contents;
    vertical-align: top;
    color: #ffff;
}

.fireplace {
    box-shadow: 20px 20px rgba(0, 0, 0, .15);
    max-height: 24rem;
    margin-top: 5rem;
    margin-left: 10rem;
}

.reachUs {
    background-color: #929292;
    box-shadow: 0px 5px 10px 0px #929292;
    padding: 0.5rem;
    max-width: 19rem;
    margin: 1rem;
    float: left;
}

.moreAboutLoyaGranite {
    border-bottom: #fc0303 solid 2px;
    border-left: #fc0303 solid 2px;
    padding: 0.5rem;
    max-width: 49rem;
    margin: 1rem;
    float: left;
}

.logoImage {
    max-height: 19rem;
    float: left;
    margin-top: 2rem;
}

@media only screen and (max-width: 1024px) {
    .aboutbottom {
        display: grid;
        height: 1100px;
    }

    .aboutLoyaGraniteSection {
        margin: auto;
        width: 50rem;
    }

    .fireplace {
        max-height: 24rem;
        /* margin-top: 10rem; */
        margin: auto;
    }
}

@media (max-width: 640px) {
    .logoImage {
        display: none;
    }

    .aboutbottom {
        /* height: 1200px; */
        margin-bottom: 16rem;
    }

    .granite {
        max-width: 100%;
        margin-top: 0;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }

    .aboutLoyaGraniteSection {
        margin: auto;
        float: none;
        width: 20rem;
    }

    .fireplace {
        max-width: 19rem;
        display: block;
        margin: auto;
        margin-top: 1rem;
    }
}

/* @media (min-width: 641px) {



    .aboutLoyaGraniteSection,
    .reachUs {
        margin-top: 5rem;
        margin-left: 5rem;
    }

} */