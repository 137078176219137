.container {
    display: grid;
    justify-content: center;
}

.locationTitle {
    text-align: center;
    padding: 1rem;
}

.map {
    border: none !important;
}

@media only screen and (max-width: 640px) {

    .map {
        max-width: 90%;
        padding: 1.2rem;
    }
}