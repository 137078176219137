.contactContain {
    height: 1000px;
}


#contactForm {
    padding: 1rem;
    display: grid;
    max-width: 80%;
    min-width: 50%;
    margin: auto;
    justify-items: center;
    margin-top: 3rem;
    margin-bottom: 3rem;
    box-shadow: 1px 1px 5px 5px #7c8798;
}

.formText {
    font-family:
        'Segoe UI',
        'Roboto',
        'Oxygen',
        'Ubuntu',
        'Cantarell',
        'Fira Sans',
        'Droid Sans',
        'Helvetica Neue',
        sans-serif;
    font-weight: 500;
    margin: 1rem 1rem 0rem 0rem
}

.userInput {
    min-height: 2rem;
}


.emailSection,
.phoneSection,
.nameSection,
.messageSection {
    width: 70%;
}

.inputEmail,
.inputPhone,
.inputName {
    width: 100%;
}

.inputText {
    resize: none;
    height: 10rem;
    width: 100%;
}

.button {
    height: 2rem;
    width: 70%;
    font-size: inherit;
    font-weight: 700;
    color: #ffff;
    background: #7c8798;
    border: none;
    cursor: pointer;
    margin-top: 1rem;
}

.button:hover {
    background: #3b414a;

}

.sub_section {
    background-color: #f2f2f2;
    display: block;
    max-width: 25rem;
    min-width: 10rem;
    float: left;
    padding: 1rem;
}

.formLogo {
    display: block;
    max-height: 14rem;
    margin: auto;
}

.questionsEnglish {
    border-top: solid 2px black;
}

.spanish {
    margin-top: 1rem;
}

@media only screen and (max-width: 822px) {

    #contactForm {
        float: none;
        margin: auto;

    }

    .banner {
        margin-bottom: 3rem;
    }

    .emailSection,
    .phoneSection,
    .nameSection,
    .messageSection,
    .button {
        width: 100%;
    }
}


@media only screen and (max-width: 640px) {
    .sub_section {
        margin-left: 2rem;
    }

    input,
    textArea,
    .button {
        max-width: 16rem !important;
    }

    form,
    form>div {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }

    .englishHeader {
        padding: 5px;
        text-align: center;
    }

    .title {
        font-size: 26px;
    }
}